import React from 'react';
import { string } from 'prop-types';

const launchConsentManager = () => {
  if (window.Osano) {
    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  } else {
    window.location = 'https://smartcar.com/privacy#cookies';
  }
};

const Footer = ({ classnames }) => {
  return (
    <footer className={`py-12 lg:py-20 ${classnames}`}>
      <ul className="container flex flex-wrap md:flex-nowrap justify-center space-x-6">
        <li>
          <a
            href="https://smartcar.com/privacy"
            className="no-underline text-sm font-normal hover:underline"
          >
            Privacy policy
          </a>
        </li>
        <li>
          <button
            type="button"
            id="cookie-settings-button"
            onClick={launchConsentManager}
            className="text-sm hover:underline"
          >
            Cookie settings
          </button>
        </li>
      </ul>
      <div className="flex flex-col justify-center items-center text-center text-xs font-normal pt-4 ">
        <div>{`© Smartcar, Inc. ${new Date().getFullYear()}`}</div>
        <div>
          This work is licensed under a{' '}
          <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
            Creative Commons Attribution 4.0 International License
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

Footer.propTypes = {
  classnames: string,
};

Footer.defaultProps = {
  classnames: '',
};
